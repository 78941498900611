export default {
  data() {
    return {
      // --- variables --- //
      fullName: "",
      lastName: "",
      password: "",
      confirmPassword: "",
      email: "",
      bShowPassword: false,
      bShowConfirmPassword: false,

      // --- language texts --- //
      texts: "",
    };
  },
  beforeMount() {
    this.texts = FILE.registerTexts[this.selectLanguage];
  },
  methods: {
    login() {
      //we store the access token in localstorage, so that we can use it again.
      this.$store.commit(
        "setToken",
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2MTk2NDU2MzUzMzAsInNFbWFpbCI6Imh1Z28uY29zdGlsbGFAc29mZXguY29tLm14Iiwic0Z1bGxOYW1lIjoiSHVnbyBDb3N0aWxsYSIsInNVc2VySWQiOiI2MDU5MmFiZTkyM2VlMDU1OGE0MDE4Y2EifQ.k_TmqtVQUafpSsS7X7I_uTKtnq6hRpvwFgpbs-56K_8"
      );
      //after storing token, send user to home page.
      this.$router.push("/admin/dashboard");
    },
    login1() {
      const payload = {
        sEmail: this.email,
        sPassword: this.password,
      };
      //post credentials and get access token from laravel backend
      DB.post(`${URI}/api/v1/auth/login`, payload)
        .then((response) => {
          //we store the access token in localstorage, so that we can use it again.
          Store.commit("setToken", response.data.user.sToken);
          //we store the email in localstorage, so that we can use it again.
          Store.commit("setEmail", response.data.user.sEmail);
          //we store the full name in localstorage, so that we can use it again./
          Store.commit("setName", response.data.user.sfullName);
          //after storing token, send user to home page.
          this.$router.push("/admin/dashboard");
        })
        .catch((error) => {
                    this.mixError(error.response.data.message,error.response.status);
        });
    },
  },
  computed: {
    validateForm() {
      return (
        this.fullName !== "" &&
        this.lastName !== "" &&
        this.email !== "" &&
        this.password !== "" &&
        this.confirmPassword !== ""
      );
    },
    selectLanguage() {
      return this.$store.state.language;
    },
  },
  watch: {
    selectLanguage() {
      if (this.selectLanguage) {
        this.texts = FILE.registerTexts[this.selectLanguage];
      }
    },
  },
};